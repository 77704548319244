import cakepoolProject from "../../assets/cakepoolProject.png";
import luckyMetaverseProject from "../../assets/luckyMetaverseProject.png";
import godzinuProject from "../../assets/godzinuProject.png";
import godzStaking from "../../assets/godzStaking.png";
import rewardsCoinProject from "../../assets/rewardsCoinProject.png";
import teslaBotsProject from "../../assets/teslaBotsProject.png";
import qingLongInuProject from "../../assets/qingLongInuProject.png";
import renq from "../../assets/renq1.png";
import rentItMiner from "../../assets/rentItMiner.png";
import dcuStaking from "../../assets/dcuStaking.png";
import cerebreumProject from "../../assets/cerebreumProject.png";
import cerebreumStaking from "../../assets/cerebrumStaking.png";
import cakepoolStaking from "../../assets/cakepoolStaking.png";
import matrix from "../../assets/matrix.png";
import lmetaDapp from "../../assets/lmetaDapp.png";
import lmetaDashboard from "../../assets/lmetaDashboard.png";
import metaLoop from "../../assets/metaloop.png";
import kuppyStake from "../../assets/kuppyStake.png";
import kuppyCalci from "../../assets/kuppyCalci.png";
import crokingProject from "../../assets/crokingProject1.png";
import aiVerseProject from "../../assets/aiVerseProject.png";
import cakeCalc from "../../assets/cakeCalc.png";
import luckyCalc from "../../assets/luckyCalc.png";
import kuppyWebsite from "../../assets/kuppyWebsite.png";
import kuppyMint from "../../assets/kuppyClansProject1.png";
import rewardsCoin from "../../assets/rewardsCoin.png";
import pandoraxProject from "../../assets/pandoraxProject.png";
import mktkings from "../../assets/mktkings.png";
import nucle from "../../assets/nucle.png";
import kleverWallet from "../../assets/kleverWallet.png";
import rentit from "../../assets/rentit.png";
import safeearn1 from "../../assets/safeearn1.png";
import safeearn2 from "../../assets/safeearn2.png";
import truliv from "../../assets/truliv.png";
import zolo from "../../assets/zolo.png";
import lottery from "../../assets/lottery.png";
import luckyMetaverseStakingProject from "../../assets/luckyMetaverseStakingProject.png";
import metamarketingProject from "../../assets/metamarketingProject.png";
import crokingDashboardProject from "../../assets/crokingDashBoardProject.png";
import purewalletProject from "../../assets/purewalletProject.png";
import crokingStakingProject from "../../assets/crokingStakingProject.png";
import cosmikProject from "../../assets/cosmikProject.png";
import kuppyClansProject from "../../assets/kuppyClansProject.png";
export const contents = {
  websites: [
    {
      projectName: "Cake Pool",
      index: "01",
      projectDescription:
        "Cake Pool operates a communal skating platform on PancakeSwap. Together with several dashboards that effectively fetch data from Blockchain, we developed a user experience that is attractive, engaging and informative.",
      projectWebsite: "https://cake-pool.vercel.app/",
      projectImage: cakepoolProject,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/cakepool",
      backgroundColor: "#86EBF8",
      textColor: "#185C83C9",
      headerColor: "#00707D",
      techUsed: "React, Javascript, SCSS",
    },
    {
      projectName: "Lucky Metaverse",
      index: "02",
      projectDescription:
        "A variety of applications build upon decentralised networks, like staking, token migration,  calculator and dashboard. You can now migrate your old token into new (v2) tokens with the help of all new token migration on our dapp. Not only this, you can access the calculator and dashboard to track your assets",
      projectWebsite: "https://lmeta.netlify.app",
      projectImage: luckyMetaverseProject,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/Luckymetaverse_official",
      backgroundColor: "#64A4FF",
      textColor: "#0E183FC7",
      headerColor: "#0E183F",
      techUsed: "HTML, CSS, Javascript",
    },
    {
      projectName: "Godzinu",
      index: "03",
      projectDescription:
        "Godzinu, is a multichain dex aggregator for lighting fast transactions on multiple chains, with GODZ you can swap in 50+ blockchains amongst 10000+ assets/tokens, and see the live rate while swapping the token with the fees required to swap.",
      projectWebsite: "https://godzilla-project.vercel.app/",
      projectImage: godzinuProject,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/Godzilla_io",
      backgroundColor: "#5483E2",
      headerColor: "#002640",
      textColor: "#002640D4",
      techUsed: "React, EtherJs, Solidity, SCSS",
    },
    {
      projectName: "Rewards Coin",
      index: "04",
      projectDescription:
        "A platform where you directly receive BNB rewards without staking or claiming! We included top-notch 3D animations in the design of this website.",
      projectWebsite: "https://rewardscoin.tech/",
      projectImage: rewardsCoin,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/+GVls0L3Ta880NjM0",
      backgroundColor: "#0F41A8",
      headerColor: "#fff",
      textColor: "#fff",
      techUsed: "React, Javascript, SCSS",
    },
    {
      projectName: "Kuppy Clans",
      index: "05",
      projectDescription:
        "Kuppy clans is a real-time strategy game set in a unique ecosystem that uses our native Tokens- Canine and Feline Master. Kuppy Clans has the potential to revolutionize the gaming industry and provide a fun and engaging learning experience for players worldwide.",
      projectWebsite: "https://kuppyclans.com/",
      projectImage: kuppyWebsite,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/kuppyclans",
      backgroundColor: "#21C677",
      headerColor: "#024B00",
      textColor: "#024B00",
      techUsed: "React, Javascript, SCSS, EtherJs",
    },

    {
      projectName: "AI Verse",
      index: "06",
      projectDescription:
        "Curated a homepage website for AI Verse, an artificial intelligence investment adviser that allows on-chain transactions for DeFi goods, decentralised AI evaluation of wealth management products, liquidity mining, private, and smart evaluation of presale risk.",
      projectWebsite: "https://ai-verse.netlify.app/",
      projectImage: aiVerseProject,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/AIverse_global",
      backgroundColor: "#FFB47D",
      headerColor: "#DD4300",
      textColor: "rgba(54, 23, 0, 0.92)",
      techUsed: "React, Javascript, SCSS",
    },

    {
      projectName: "MKT Kings",
      index: "07",
      projectDescription:
        "MarketKings is a development and marketing agency dedicated to helping EVM projects by raising capital funds and providing unique utility to their token holders with 45+ completed projects and almost 1M$ funds raised.",
      projectWebsite: "cakepool.io",
      projectImage: mktkings,
      connectText: "Visit Website",
      projectTelegram: "",
      backgroundColor: "#C7AB64",
      headerColor: "#624600",
      textColor: "#342500AD",
    },
    {
      projectName: "Rent It",
      index: "08",
      projectDescription:
        "Say goodbye to traditional real estate investments with high barriers to entry. RentIt's fractional real estate investment platform makes it easy for anyone to invest in real estate. We use web3 to overcome the issue of the huge capital required for buying land, so now just hold the portion of the land according to your budget, also you can mine and earn good profits.",
      projectWebsite: "https://rentit.website/",
      projectImage: rentit,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/RentitBSC",
      backgroundColor: "#BBAA8F",
      headerColor: "#624600",
      textColor: "#62460099",
    },
    {
      projectName: "Cerebreum",
      index: "09",
      projectDescription:
        "Offering a mystical motif and providing easy navigation, we designed a homepage website and staking platform for APY Token, the first-ever, sustainable and compounding token!",
      projectWebsite: "https://cerebreum.netlify.app/",
      projectImage: cerebreumProject,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/cerebreumai",
      backgroundColor: "#FF7DB6",
      headerColor: "#680034",
      textColor: "rgba(104, 0, 52, 0.74)",
    },

    {
      projectName: "Kuppy Clans Mint",
      index: "10",
      projectDescription:
        "Kupply clans provide a unique NFT minting, you can mint Village Land with just some $CM and $FM Tokens, and the best part about this is Land prices increase by 500 $FM/$CM for every 1000 land sold",
      projectWebsite: "https://kuppyclans.com/mint",
      projectImage: kuppyMint,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/kuppyclans",
      backgroundColor: "#21C677",
      headerColor: "#024B00",
      textColor: "#024B00",
      techUsed: "React, Javascript, SCSS, EtherJs",
    },

    {
      projectName: "Renq ICO",
      index: "11",
      projectDescription:
        "Renq is a community-driven organization that is established to provide a one-stop solution for all kinds of traders under one platform in the DeFi world. Renq has special features like Multi Chain Dex and a Never Ending Liquidity",
      projectWebsite: "https://renq.io/",
      projectImage: renq,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/renqfinance",
      backgroundColor: "#58C1EF",
      headerColor: "#0B1D33",
      textColor: "#0B1D33",
    },

    {
      projectName: "Meta Loop",
      index: "12",
      projectDescription:
        "A special dapp in which  user can swap their NFT from one Chain to another, it is one of the rarest dapp of its kind, user can connect wallet through metamask and trustwallet and as soon as they connect wallet they can see all the NFTs they hold and can easily transfer them to other blockchains",
      projectWebsite: "https://meta-loop.netlify.app/",
      projectImage: metaLoop,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/metaloopofficial",
      backgroundColor: "#4792E4",
      textColor: "#131627",
      headerColor: "#141628",
      techUsed: "HTML, CSS, Javascript",
    },
    {
      projectName: "LMETA",
      index: "13",
      projectDescription:
        "We have a variety of applications built upon decentralised networks, like staking, token migration and calculator and a dashboard, you can now migrate your old token into new (v2) tokens with the help of all new token migration on our dapp, also can access the calculator and dashboard to track your assets",
      projectWebsite: "https://www.dashboard.luckymetaverse.org/",
      projectImage: lmetaDapp,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/Luckymetaverse_official",
      backgroundColor: "#3F84E5",
      textColor: "#002640",
      headerColor: "#002640",
      techUsed: "HTML, CSS, Javascript",
    },

    {
      projectName: "Croking",
      index: "14",
      projectDescription:
        "With the help of our Multi-Chain DEX aggregator, users may access several decentralised exchanges (DEXs) from a single interface. It seeks to give cryptocurrency traders a smooth and effective trading experience. This design includes features like token swaps, multi-chap support, optimised nodes for low gas costs, and a royal layout with gorgeous graphics.",
      projectWebsite: "https://croking.netlify.app/",
      projectImage: crokingProject,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/CroKingOfficial",
      backgroundColor: "#FFD66B",
      headerColor: "#624600",
      textColor: "rgba(52, 37, 0, 0.68)",
    },
    {
      projectName: "Cakepool Calculator",
      index: "15",
      projectDescription:
        "CakePool is a community pool used for staking Cake on PancakeSwap. We are a rewards token working towards self-sustainability instead of relying on daily trading volume. Most reward tokens rely solely on trading volume. If there is little to no volume then there will be no rewards. CakePool is set up in a way where rewards will be given out even if zero trading is being done.",
      projectWebsite: "https://cakepool-reward-dashboard.netlify.app/",
      projectImage: cakeCalc,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/CakePoolStaking",
      backgroundColor: "#86EBF8",
      headerColor: "#00707D",
      textColor: "rgba(24, 92, 131, 0.79)",
    },
    {
      projectName: "LMETA Calculator",
      index: "16",
      projectDescription:
        "Calculation of rewards is now easy with Lmeta calculator, check your returns on investment with the help of lmeta calculator. Lucky Metaverse is a rebranding and restructuring of the old project Metaface.",
      projectWebsite: "https://www.dashboard.luckymetaverse.org/",
      projectImage: luckyCalc,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/Luckymetaverse_official",
      backgroundColor: "#64A4FF",
      headerColor: "#0E183F",
      textColor: "rgba(14, 24, 63, 0.78)",
    },

    {
      projectName: "Cosmic Finance",
      index: "17",
      projectDescription:
        "Cosmic Finance focuses on a dashboard where you can track all of your crypto assets, manage it and also you can track the next rebase of the token and can swap the token from the dashboard. Also, rainbow kit is integrated into it, so connecting your wallet will be very easy.",
      projectWebsite: "https://main--cosmicfinances.netlify.app",
      projectImage: cosmikProject,
      connectText: "Visit Website",
      projectTelegram: "https://discord.com/invite/CosmikFinance",
      backgroundColor: "#FFAAD4",
      headerColor: "##680034",
      textColor: "#680034AB",
    },

    {
      projectName: "Safe Earn",
      index: "18",
      projectDescription:
        "Safeearn, is a web3-based application focusing on a dashboard where you can track all of your crypto assets and manage their data. Also, rainbow kit is integrated to safeearn, so connecting your wallet will be very easy.",
      projectWebsite: "https://dashboard.safeearnfinance.net",
      projectImage: safeearn1,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/SafeEarnFinanceGroupChat",
      backgroundColor: "#BCBBFF",
      headerColor: "#7100A7",
      textColor: "rgba(113, 0, 167, 0.62)",
    },

    {
      projectName: "Lmeta token migration dashboard ",
      index: "19",
      projectDescription:
        "Lmeta migration is used to migrate your old token into new (v2) tokens with the help of all new token migration on our dapp, to make the process streamlined and transparent, we came up with the migration to maintain the trust and integrity of previous holder of project we launched migration so that you can get the true worth of your old tokens while moving to V2",
      projectWebsite: "https://www.dashboard.luckymetaverse.org/#/migrate",
      projectImage: lmetaDashboard,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/Luckymetaverse_official",
      backgroundColor: "#64A4FF",
      headerColor: "#0E183F",
      textColor: "rgba(14, 24, 63, 0.78)",
    },

    {
      projectName: "Kuppy Clans Calculator",
      index: "20",
      projectDescription:
        "Kuppy clans is a real-time strategy game set in a unique ecosystem that uses our native Tokens- Canine and Feline Master. Kuppy Clans has the potential to revolutionize the gaming industry and provide a fun and engaging learning experience for players all over the world",
      projectWebsite: "https://kuppyclans.com/stake/calci",
      projectImage: kuppyCalci,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/kuppyclans",
      backgroundColor: "#21C677",
      headerColor: "#024B00",
      textColor: "##024B00",
    },

    {
      projectName: "Cakepool Staking",
      index: "21",
      projectDescription:
        "Stake your $CAKE and earn passive income on your investment, to make process seamline you can connect your wallet in multiple ways, check who is staking, withdrawing and lots of information on our platform",
      projectWebsite: "https://cakepoolstaking.com/",
      projectImage: cakepoolStaking,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/CakePoolStaking",
      backgroundColor: "#86EBF8",
      headerColor: "#00707D",
      textColor: "#185C83C9",
    },
    {
      projectName: "Godzilla Staking",
      index: "22",
      projectDescription:
        "Stake your $GODZ or $GODZLP tokens to earn rewards funded by Buy and Sale Tax, earn every time a person buys or sells the token, with a staking period of only 30 days and average APY, you can connect your wallet in multiple ways.",
      projectWebsite: "https://godzilla-swap.netlify.app/",
      projectImage: godzStaking,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/Godzilla_io",
      backgroundColor: "#5483E2",
      headerColor: "#002640",
      textColor: "rgba(0, 38, 64, 0.83)",
    },

    {
      projectName: "Cerebreum Staking",
      index: "23",
      projectDescription:
        "With Cerebreum staking, you can stake your token and earn passive income. With different staking periods and an average of 9% APY, you can connect your wallet in multiple ways.",
      projectWebsite: "https://cerebreum.netlify.app/",
      projectImage: cerebreumStaking,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/cerebreumai",
      backgroundColor: "#FF7DB6",
      headerColor: "#680034",
      textColor: "rgba(104, 0, 52, 0.74)",
    },

    {
      projectName: "Rent It Mining",
      index: "25",
      projectDescription:
        "Investing in real estate has never been this easy! With #RentIt, you can invest in fractional shares of properties from the comfort of your own home, participate in our mining by depositing any amount for 7/30 days and earn Rewards",
      projectWebsite: "https://dashboard.safeearnfinance.net",
      projectImage: rentItMiner,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/RentitBSC",
      backgroundColor: "#BBAA8F",
      headerColor: "#624600",
      textColor: "#62460099",
    },
    {
      projectName: "Kuppy Clans Staking",
      index: "26",
      projectDescription:
        "Kuppy clans staking is an advanced staking platform where you can stake both of your token Canine($CM) and Feline ($FM) for 30/60/90 days depending on how much APY you want and capital you can hold.",
      projectWebsite: "https://kuppyclans.com/stake",
      projectImage: kuppyStake,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/kuppyclans",
      backgroundColor: "#21C677",
      headerColor: "#024B00",
      textColor: "#024B00",
      techUsed: "React, Javascript, SCSS, EtherJs",
    },

    {
      projectName: "DCU Staking",
      index: "27",
      projectDescription:
        "Dcu staking is a multiple staking platform where you can stake your token for 30/60/90/180 days, depending on how much APY you want. It varies from 10-23 depending upon the number of days locked. It is also integrated with Rainbow Kit, so you easily connect your wallet and stake tokens.",
      projectWebsite: "https://dcustaking.netlify.app/",
      projectImage: dcuStaking,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/DecentralizedUnited",
      backgroundColor: "#3F8EF7",
      headerColor: "#001827",
      textColor: "#001827",
    },
    {
      projectName: "Matrix",
      index: "28",
      projectDescription:
        "A unique concept website that show us how can we escape the matrix, this website is completely based on the choices people make, you can choose a blue pill (easy way) or red pill (tough way) ",
      projectWebsite: "https://matrix-labs-pills.netlify.app/",
      projectImage: matrix,
      connectText: "Visit Website",
      projectTelegram: "https://t.me/EscapeTheMatrix_Bep20",
      backgroundColor: "#B5FFB1",
      headerColor: "#045B00",
      textColor: "rgba(4, 91, 0, 0.76)",
    },
    {
      projectName: "NWT-DAO Lottery",
      projectDescription:
        "A web3 based lottery application with no bias, The Contract Randomly Selects 9 Lucky Winners And Sents The Rewards To Their Address Automatically. Everything Is Open, Safe, And Decentralized. Users Can Go To Their Wallet And Check.",
      projectWebsite: "https://probable-system-9ocf1ul9c-mfrancisdy.vercel.app/",
      projectImage: lottery,
      connectText: "Visit Website",
      projectTelegram: "https://probable-system-9ocf1ul9c-mfrancisdy.vercel.app/",
      backgroundColor: "#6A68A4",
      headerColor: "#FFFFFF",
      textColor: "rgba(255, 255, 255, 0.72)",
    },
    {
      projectName: "Zolo",
      index: "29",
      projectDescription:
        "Find the perfect accommodation in your city with Zolo Coliving. Don't waste time and money on bad deals from brokers. Our hassle-free rental options across 10 Indian cities are just a click away on the Zolo app",
      projectWebsite:
        "https://play.google.com/store/apps/details?id=com.zelo.customer",
      projectImage: zolo,
      connectText: "Visit Website",
      backgroundColor: "#51ADE5",
      headerColor: "#004370",
      textColor: "#004370D4",
    },
    {
      projectName: "Klever Wallet",
      index: "30",
      projectDescription:
        "Klever Wallet has got you covered for all your crypto needs. Access over 17 blockchain networks and store your coins and tokens securely. Klever Wallet supports top crypto assets like BTC, TRX, ETH, and KLV, giving you the anonymity you need.      ",
      projectWebsite:
        "https://play.google.com/store/apps/details?id=finance.klever.bitcoin.wallet",
      projectImage: kleverWallet,
      connectText: "Visit Website",
      backgroundColor: "#CFB8FF",
      headerColor: "#20136A",
      textColor: "#20136A",
    },

    {
      projectName: "Truliv",
      index: "31",
      projectDescription:
        "Truliv makes home maintenance and society payments a breeze. Schedule appointments, raise tickets, view announcements and engage with your neighbours. Pay your bills and manage your home with ease, all through one convenient app.      ",
      projectWebsite:
        "https://play.google.com/store/apps/details?id=com.truliv.community",
      projectImage: truliv,
      connectText: "Visit Website",
      backgroundColor: "#FFC48D",
      headerColor: "#CB5500",
      textColor: "#CB5500",
    },
    {
      projectName: "Nucle",
      index: "32",
      projectDescription:
        "Nucle is a secure and user-friendly crypto wallet that allows for easy transactions through QR code scans. Trust us to keep your assets safe and transactions smooth",
      projectWebsite: "https://play.google.com/store/apps/details?id=io.nucle",
      projectImage: nucle,
      connectText: "Visit Website",
      backgroundColor: "#97ADFF",
      headerColor: "#0F0061",
      textColor: "#0F0061",
    },
  ],
};
